import * as jwt from 'jsonwebtoken';
import * as bcrypt from 'bcryptjs';

const useSecret = () => {
  return {
    secret: 'd0f09eba545e4290888e0d0c47e00515',
  };
};

export interface IAuthService {
  login({ password }: any): void;
  logout(): void;
  authenticated(): boolean;
}

export class AuthService implements IAuthService {
  constructor(private users: []) { }
  //Todo: make this async?
  async login({ password }: any) {
    const user = this.users.find(x => bcrypt.compareSync(password, x.hash));

    if (user) {
      const { secret } = useSecret();
      //todo: regenerate and put in config
      const token = jwt.sign({ user }, secret);
      localStorage.setItem('token', token);
    }
  }

  //Todo: make this async?
  logout() {
    localStorage.removeItem('token');
  }

  //Todo: make this async?
  authenticated() {
    const token = localStorage.getItem('token');
    return this.verify({ token });
  }

  //Todo: make this async?
  verify({ token }: any) {
    const { secret } = useSecret();
    return !!(token && jwt.verify(token, secret));
  }
}
