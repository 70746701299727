import React, { createContext, useEffect, useState, ReactNode } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AuthService, IAuthService } from './auth.service';

export type AuthProviderProps = {
  children: ReactNode;
  navigate: (path: string) => void;
};

export type AuthContextState = {
  service: IAuthService | null;
  navigate: (path: string) => void;
};

export const AuthContext = createContext<AuthContextState>({
  service: null,
  navigate: (path: string) => {},
});

if (process.env.NODE_ENV !== 'production') {
  AuthContext.displayName = 'AuthContext';
}

export const AuthProvider = ({ children, navigate }: AuthProviderProps) => {
  const data: any = useStaticQuery(graphql`
    {
      users: allUsersJson {
        edges {
          node {
            id
            hash
          }
        }
      }
    }
  `);

  const users = () => {
    return data.users.edges.map(({ node }) => node);
  };
  const service = new AuthService(users());

  const [state, setState] = useState<AuthContextState>({
    service,
    navigate,
  });

  useEffect(() => {}, []);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
