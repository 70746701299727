import { useContext } from 'react';
import { AuthContext } from './auth.provider';

export const useAuth = () => {
  const { navigate, service } = useContext(AuthContext);

  const login = ({ password }: { password: string }) => {

    const token = service?.login({ password });
    if (token) {
      handleAuthentication();
    }
  };

  const logout = () => {
    service?.logout();
    navigate('/login');
  };

  const handleAuthentication = ({ route = '/' } = {}) => {
    if (typeof window !== 'undefined') {
      navigate(route);
    }
  };

  const authenticated = () => {
    return service?.authenticated();
  };

  return {
    authenticated,
    login,
    logout,
    navigate
  };
};
