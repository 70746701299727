import React from 'react';
import { useAuth } from './use.auth';

export type AuthRouteProps = {
  component: any;
  location: any;
};

export const AuthRoute = ({ component: Component, location, ...rest }: AuthRouteProps) => {
  const { authenticated, navigate } = useAuth();

  if (!authenticated() && location.pathname !== '/login') {
    navigate('/login');
    return null;
  }

  return (
    <>
      <Component {...rest} />
    </>
  );
};

export default AuthRoute;
